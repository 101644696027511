export const CADESCOM_ATTRIBUTE_OTHER = -1;
export const CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_DESCRIPTION = 2;
export const CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME = 1;
export const CADESCOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME = 0;
export const CADESCOM_AllowNoOutstandingRequest = 1;
export const CADESCOM_AllowNone = 0;
export const CADESCOM_AllowUntrustedCertificate = 2;
export const CADESCOM_AllowUntrustedRoot = 4;
export const CADESCOM_BASE64_TO_BINARY = 0x01;
export const CADESCOM_CADES_BES = 1;
export const CADESCOM_CADES_DEFAULT = 0;
export const CADESCOM_CADES_T = 5;
export const CADESCOM_CADES_X_LONG_TYPE_1 = 0x5d;
export const CADESCOM_CONTAINER_STORE = 100;
export const CADESCOM_CURRENT_USER_STORE = 2;
export const CADESCOM_DISPLAY_DATA_ATTRIBUTE = 2;
export const CADESCOM_DISPLAY_DATA_CONTENT = 1;
export const CADESCOM_DISPLAY_DATA_NONE = 0;
export const CADESCOM_ENCODE_ANY = -1;
export const CADESCOM_ENCODE_BASE64 = 0;
export const CADESCOM_ENCODE_BINARY = 1;
export const CADESCOM_ENCRYPTION_ALGORITHM_3DES = 3;
export const CADESCOM_ENCRYPTION_ALGORITHM_AES = 4;
export const CADESCOM_ENCRYPTION_ALGORITHM_DES = 2;
export const CADESCOM_ENCRYPTION_ALGORITHM_GOST_28147_89 = 25;
export const CADESCOM_ENCRYPTION_ALGORITHM_RC2 = 0;
export const CADESCOM_ENCRYPTION_ALGORITHM_RC4 = 1;
export const CADESCOM_HASH_ALGORITHM_CP_GOST_3411 = 100;
export const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256 = 101;
export const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256_HMAC = 111;
export const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512 = 102;
export const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512_HMAC = 112;
export const CADESCOM_HASH_ALGORITHM_CP_GOST_3411_HMAC = 110;
export const CADESCOM_HASH_ALGORITHM_MD2 = 1;
export const CADESCOM_HASH_ALGORITHM_MD4 = 2;
export const CADESCOM_HASH_ALGORITHM_MD5 = 3;
export const CADESCOM_HASH_ALGORITHM_SHA1 = 0;
export const CADESCOM_HASH_ALGORITHM_SHA_256 = 4;
export const CADESCOM_HASH_ALGORITHM_SHA_384 = 5;
export const CADESCOM_HASH_ALGORITHM_SHA_512 = 6;
export const CADESCOM_LOCAL_MACHINE_STORE = 1;
export const CADESCOM_PKCS7_TYPE = 65535;
export const CADESCOM_STRING_TO_UCS2LE = 0x00;
export const CADESCOM_SkipInstallToStore = 268435456;
export const CADESCOM_XML_SIGNATURE_TYPE_ENVELOPED = 0;
export const CADESCOM_XML_SIGNATURE_TYPE_ENVELOPING = 1;
export const CADESCOM_XML_SIGNATURE_TYPE_TEMPLATE = 2;
export const CAPICOM_ACTIVE_DIRECTORY_USER_STORE = 3;
export const CAPICOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_DESCRIPTION = 2;
export const CAPICOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME = 1;
export const CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME = 0;
export const CAPICOM_CERTIFICATE_FIND_APPLICATION_POLICY = 7;
export const CAPICOM_CERTIFICATE_FIND_CERTIFICATE_POLICY = 8;
export const CAPICOM_CERTIFICATE_FIND_EXTENDED_PROPERTY = 6;
export const CAPICOM_CERTIFICATE_FIND_EXTENSION = 5;
export const CAPICOM_CERTIFICATE_FIND_ISSUER_NAME = 2;
export const CAPICOM_CERTIFICATE_FIND_KEY_USAGE = 12;
export const CAPICOM_CERTIFICATE_FIND_ROOT_NAME = 3;
export const CAPICOM_CERTIFICATE_FIND_SHA1_HASH = 0;
export const CAPICOM_CERTIFICATE_FIND_SUBJECT_NAME = 1;
export const CAPICOM_CERTIFICATE_FIND_TEMPLATE_NAME = 4;
export const CAPICOM_CERTIFICATE_FIND_TIME_EXPIRED = 11;
export const CAPICOM_CERTIFICATE_FIND_TIME_NOT_YET_VALID = 10;
export const CAPICOM_CERTIFICATE_FIND_TIME_VALID = 9;
export const CAPICOM_CERTIFICATE_INCLUDE_CHAIN_EXCEPT_ROOT = 0;
export const CAPICOM_CERTIFICATE_INCLUDE_END_ENTITY_ONLY = 2;
export const CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN = 1;
export const CAPICOM_CERT_INFO_ISSUER_SIMPLE_NAME = 1;
export const CAPICOM_CERT_INFO_SUBJECT_SIMPLE_NAME = 0;
export const CAPICOM_CURRENT_USER_STORE = 2;
export const CAPICOM_DIGITAL_SIGNATURE_KEY_USAGE = 128;
export const CAPICOM_EKU_CLIENT_AUTH = 2;
export const CAPICOM_EKU_OTHER = 0;
export const CAPICOM_EKU_SMARTCARD_LOGON = 5;
export const CAPICOM_LOCAL_MACHINE_STORE = 1;
export const CAPICOM_MEMORY_STORE = 0;
export const CAPICOM_MY_STORE = 'My';
export const CAPICOM_OID_KEY_USAGE_EXTENSION = 10;
export const CAPICOM_OID_OTHER = 0;
export const CAPICOM_PROPID_ACCESS_STATE = 14;
export const CAPICOM_PROPID_ARCHIVED = 19;
export const CAPICOM_PROPID_ARCHIVED_KEY_HASH = 65;
export const CAPICOM_PROPID_AUTO_ENROLL = 21;
export const CAPICOM_PROPID_CROSS_CERT_DIST_POINTS = 23;
export const CAPICOM_PROPID_CTL_USAGE = 9;
export const CAPICOM_PROPID_DATE_STAMP = 27;
export const CAPICOM_PROPID_DESCRIPTION = 13;
export const CAPICOM_PROPID_EFS = 17;
export const CAPICOM_PROPID_ENHKEY_USAGE = 9;
export const CAPICOM_PROPID_ENROLLMENT = 26;
export const CAPICOM_PROPID_EXTENDED_ERROR_INFO = 30;
export const CAPICOM_PROPID_FIRST_RESERVED = 66;
export const CAPICOM_PROPID_FIRST_USER = 0x00008000;
export const CAPICOM_PROPID_FORTEZZA_DATA = 18;
export const CAPICOM_PROPID_FRIENDLY_NAME = 11;
export const CAPICOM_PROPID_HASH_PROP = 3;
export const CAPICOM_PROPID_IE30_RESERVED = 7;
export const CAPICOM_PROPID_ISSUER_PUBLIC_KEY_MD5_HASH = 24;
export const CAPICOM_PROPID_ISSUER_SERIAL_NUMBER_MD5_HASH = 28;
export const CAPICOM_PROPID_KEY_CONTEXT = 5;
export const CAPICOM_PROPID_KEY_IDENTIFIER = 20;
export const CAPICOM_PROPID_KEY_PROV_HANDLE = 1;
export const CAPICOM_PROPID_KEY_PROV_INFO = 2;
export const CAPICOM_PROPID_KEY_SPEC = 6;
export const CAPICOM_PROPID_LAST_RESERVED = 0x00007fff;
export const CAPICOM_PROPID_LAST_USER = 0x0000ffff;
export const CAPICOM_PROPID_MD5_HASH = 4;
export const CAPICOM_PROPID_NEXT_UPDATE_LOCATION = 10;
export const CAPICOM_PROPID_PUBKEY_ALG_PARA = 22;
export const CAPICOM_PROPID_PUBKEY_HASH_RESERVED = 8;
export const CAPICOM_PROPID_PVK_FILE = 12;
export const CAPICOM_PROPID_RENEWAL = 64;
export const CAPICOM_PROPID_SHA1_HASH = 3;
export const CAPICOM_PROPID_SIGNATURE_HASH = 15;
export const CAPICOM_PROPID_SMART_CARD_DATA = 16;
export const CAPICOM_PROPID_SUBJECT_NAME_MD5_HASH = 29;
export const CAPICOM_PROPID_SUBJECT_PUBLIC_KEY_MD5_HASH = 25;
export const CAPICOM_PROPID_UNKNOWN = 0;
export const CAPICOM_SMART_CARD_USER_STORE = 4;
export const CAPICOM_STORE_OPEN_EXISTING_ONLY = 128;
export const CAPICOM_STORE_OPEN_INCLUDE_ARCHIVED = 256;
export const CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED = 2;
export const CAPICOM_STORE_OPEN_READ_ONLY = 0;
export const CAPICOM_STORE_OPEN_READ_WRITE = 1;
export const CHECK_NONE = 0;
export const CHECK_OFFLINE_REVOCATION_STATUS = 16;
export const CHECK_ONLINE_REVOCATION_STATUS = 8;
export const CHECK_SIGNATURE_VALIDITY = 4;
export const CHECK_TIME_VALIDITY = 2;
export const CHECK_TRUSTED_ROOT = 1;
export const LOG_LEVEL_DEBUG = 4;
export const LOG_LEVEL_ERROR = 1;
export const LOG_LEVEL_INFO = 2;
export const TRUST_CTL_IS_NOT_SIGNATURE_VALID = 262144;
export const TRUST_CTL_IS_NOT_TIME_VALID = 131072;
export const TRUST_CTL_IS_NOT_VALID_FOR_USAGE = 524288;
export const TRUST_IS_CYCLIC = 128;
export const TRUST_IS_NOT_SIGNATURE_VALID = 8;
export const TRUST_IS_NOT_TIME_NESTED = 2;
export const TRUST_IS_NOT_TIME_VALID = 1;
export const TRUST_IS_NOT_VALID_FOR_USAGE = 16;
export const TRUST_IS_PARTIAL_CHAIN = 65536;
export const TRUST_IS_REVOKED = 4;
export const TRUST_IS_UNTRUSTED_ROOT = 32;
export const TRUST_REVOCATION_STATUS_UNKNOWN = 64;
export const XmlDsigGost3410Url = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr34102001-gostr3411';
export const XmlDsigGost3410UrlObsolete = 'http://www.w3.org/2001/04/xmldsig-more#gostr34102001-gostr3411';
export const XmlDsigGost3411Url = 'urn:ietf:params:xml:ns:cpxmlsec:algorithms:gostr3411';
export const XmlDsigGost3411UrlObsolete = 'http://www.w3.org/2001/04/xmldsig-more#gostr3411';
